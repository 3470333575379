import axios from "axios";

//const endpoint = 'http://192.168.2.164/';
const endpoint = 'https://core.soffiopizzeria.com.br/';
//const endpoint = 'https://homolog.core.soffiopizzeria.com.br/';

const api = axios.create({
    baseURL: endpoint + 'api/',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    timeout: 15000,
  });

export default api;