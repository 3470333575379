import React, { useState, useEffect } from "react";
import { format, parseISO, isToday } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import api from './api.js';
import Swal from "sweetalert2";
import "./Reservation.css";

const Reservation = () => {
  const [selectedDate, setSelectedDate] = useState("");
  const [datesOptions, setDatesOptions] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [selectedPeople, setSelectedPeople] = useState("");
  const [selectedTime, setSelectedTime] = useState("");  
  const [step, setStep] = useState(1);
  const [telefone, setTelefone] = useState("");
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [observacoes, setObservacoes] = useState("");
  const [disponibilidade, setDisponibilidade] = useState([]);
  const [maxPessoas, setMaxPessoas] = useState(1);

  const fetchDisponibilidade = async () => {
    try {
      const response = await api.get("/disponibilidade");
      setDisponibilidade(response.data);
    } catch (error) {
      console.error("Erro ao buscar disponibilidade:", error);
    }
  };

  // Chama a disponibilidade quando o componente é montado
  useEffect(() => {
    fetchDisponibilidade();
  }, []);

  // Atualiza as opções de datas quando o ambiente é selecionado
  useEffect(() => {
    if (selectedArea) {
      const ambiente = disponibilidade.find(
        (amb) => amb.ambiente_id === parseInt(selectedArea)
      );
      if (ambiente) {
        const now = new Date();
        const currentHour = now.getHours();

        // Filtra a lista de dias para remover o dia atual caso o horário seja maior que 18h
        // e ignora dias com apenas 1 vaga disponível
        const filteredDates = ambiente.dias.filter((dia) => {
          const parsedDate = parseISO(dia.data);
          const isNotMonday = parsedDate.getDay() !== 1; // 1 representa segunda-feira
          return isNotMonday && !(isToday(parsedDate) && currentHour >= 18) && dia.lugares_disponiveis > 1;
        });

        setDatesOptions(filteredDates);
      }
    }
  }, [selectedArea, disponibilidade]);

  // Atualiza o número máximo de pessoas quando a data é selecionada
  useEffect(() => {
    if (selectedDate) {
      const ambiente = disponibilidade.find(
        (amb) => amb.ambiente_id === parseInt(selectedArea)
      );
      if (ambiente) {
        const diaSelecionado = ambiente.dias.find(
          (dia) => dia.data === selectedDate
        );
        if (diaSelecionado) {
          const maxPorReserva = diaSelecionado.max_por_reserva;

          const maxPessoasDisponiveis = Math.min(
            maxPorReserva,
            Math.max(0, diaSelecionado.lugares_disponiveis)
          );
          setMaxPessoas(maxPessoasDisponiveis);
        }
      }
    }
  }, [selectedDate, selectedArea, disponibilidade]);

  const handleContinue = (e) => {
    e.preventDefault();
    setStep(2);
  };

  const handleBack = (e) => {
    e.preventDefault();
    setStep(1);
  };

  const handleReservation = (e) => {
    e.preventDefault();
    const telefoneNumeros = telefone.replace(/\D/g, "");

    const dataReserva = `${selectedDate} ${selectedTime}:00`;

    const data = {
      data: dataReserva,
      ambiente_id: selectedArea,
      num_pessoas: selectedPeople,
      telefone: telefoneNumeros,
      nome: nome,
      email: email,
      observacoes: observacoes,
    };

    api
      .post("/reservas", data)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Reserva Confirmada!",
          text: `Sua reserva para o dia ${format(parseISO(selectedDate), "dd 'de' MMMM", { locale: ptBR })} foi confirmada.`,
          confirmButtonText: "OK",
        }).then(() => {          
          resetForm();
          fetchDisponibilidade();  
        });        
      })
      .catch((error) => {
        const errorMessage = error.response && error.response.data && error.response.data.message ? error.response.data.message : "Ocorreu um erro ao tentar fazer a reserva. Por favor, tente novamente.";

        if (errorMessage.includes('disponibilidade')) {
          fetchDisponibilidade();
        }

        Swal.fire({
          icon: "error",
          title: "Erro",
          text: errorMessage,
          confirmButtonText: "OK",
        });
      });
  };

  const resetForm = () => {
    setSelectedDate("");
    setSelectedArea("");
    setSelectedPeople("");
    setSelectedTime("");
    setTelefone("");
    setNome("");
    setEmail("");
    setObservacoes("");
    setStep(1);
  };

  const formatTelefone = (value) => {
    // Remove todos os caracteres que não são números
    value = value.replace(/\D/g, "");

    // Limitar a 11 dígitos
    if (value.length > 11) {
      value = value.slice(0, 11);
    }

    // Formatar o telefone (XX) XXXXX-XXXX ou (XX) XXXX-XXXX
    if (value.length > 10) {
      value = value.replace(/^(\d{2})(\d{5})(\d)/, "($1) $2-$3");
    } else if (value.length > 6) {
      value = value.replace(/^(\d{2})(\d{4})(\d)/, "($1) $2-$3");
    } else if (value.length > 2) {
      value = value.replace(/^(\d{2})(\d)/, "($1) $2");
    }

    return value;
  };

  const handleTelefoneChange = (e) => {
    const formattedTelefone = formatTelefone(e.target.value);
    setTelefone(formattedTelefone);
  };

  const handleNomeChange = (e) => {
    if (e.target.value.length <= 40) {
      setNome(e.target.value);
    }
  };

  const handleEmailChange = (e) => {
    if (e.target.value.length <= 40) {
      setEmail(e.target.value);
    }
  };

  const isSextaOuSabado = () => {
    const parsedDate = parseISO(selectedDate);
    const dayOfWeek = parsedDate.getDay();
    return dayOfWeek === 5 || dayOfWeek === 6; // 5 = Sexta, 6 = Sábado
  };

  return (
    <div className="reservation-container">
      <div className="banner"></div>
      <div className="logo"></div>
      <h1>Faça sua reserva</h1>

      {step === 1 && (
        <form className="reservation-form" onSubmit={handleContinue}>
          {/* Primeira etapa */}
          <div className="form-group">
            <label htmlFor="area">Ambiente:</label>
            <select
              id="area"
              value={selectedArea}
              onChange={(e) => setSelectedArea(e.target.value)}
              required
            >
              <option value="" disabled>
                Selecione o ambiente
              </option>
              {disponibilidade.map((ambiente, index) => (
                <option key={index} value={ambiente.ambiente_id}>
                  {ambiente.ambiente}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="date">Dia da Reserva:</label>
            <select
              id="date"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              disabled={!selectedArea}
              required
            >
              <option value="" disabled>
                Selecione o dia
              </option>
              {datesOptions.map((option) => {
                const parsedDate = parseISO(option.data);
                return (
                  <option key={option.data} value={option.data}>
                    {format(parsedDate, "dd 'de' MMMM',' EEEE", { locale: ptBR }).replace('-feira', '')}{" "}
                    {/* - {option.lugares_disponiveis} lugares disponíveis */}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="people">Número de Pessoas:</label>
            <select
              id="people"
              value={selectedPeople}
              onChange={(e) => setSelectedPeople(e.target.value)}
              disabled={!selectedDate}
              required
            >
              <option value="" disabled>
                Selecione o número de pessoas
              </option>
              {[...Array(maxPessoas).keys()].map((i) => (
                <option key={i + 1} value={i + 1}>
                  {i + 1} Pessoa{i + 1 > 1 ? "s" : ""}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="time">Horário:</label>
            <select
              id="time"
              value={selectedTime}
              onChange={(e) => setSelectedTime(e.target.value)}
              disabled={!selectedPeople}
              required
            >
              <option value="" disabled>
                Selecione o horário
              </option>
              {["18:00", "18:30", "19:00", "19:30", "20:00"]
                .filter((time) => !(time === "20:00" && isSextaOuSabado())) // Remove 20:00 se for sexta ou sábado
                .map((time) => (
                  <option key={time} value={time}>
                    {time}
                </option>
              ))}
            </select>
          </div>

          <button type="submit" className="submit-button">
            Continuar
          </button>
        </form>
      )}

      {step === 2 && (
        <>
          <button className="back-button" onClick={handleBack}>
            ← Voltar
          </button>
          <form className="reservation-form" onSubmit={handleReservation}>
            {/* Segunda etapa */}
            <div className="form-group">
              <label htmlFor="telefone">Telefone (com DDD):</label>
              <input
                type="tel"
                id="telefone"
                value={telefone}
                onChange={handleTelefoneChange}
                required
                placeholder="(11) 99999-9999"
                maxLength={15} // Limita a quantidade de caracteres para o formato
              />
            </div>

            <div className="form-group">
              <label htmlFor="nome">Nome:</label>
              <input
                type="text"
                id="nome"
                value={nome}
                onChange={handleNomeChange}
                required
                placeholder="Seu nome completo"
                maxLength={40}
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">E-mail:</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={handleEmailChange}
                required
                placeholder="seuemail@exemplo.com"
                maxLength={40}
              />
            </div>

            {/* Campo de Observações */}
            <div className="form-group">
              <label htmlFor="observacoes">Observações:</label>
              <textarea
                id="observacoes"
                value={observacoes}
                onChange={(e) => setObservacoes(e.target.value)}
                placeholder="Adicione observações sobre a reserva (opcional)"
                rows="2"
                maxLength={200}
              />
            </div>

            <button type="submit" className="submit-button">
              Reservar
            </button>
          </form>
        </>
      )}
    </div>
  );
};

export default Reservation;
